@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  box-sizing: inherit;
  border-width: 0;
  border-style: inherit;
  border-color: theme('borderColor.default', currentColor);

  &:focus {
    outline: none;
  }
}