@mixin twaice-mat-form-field() {
  .mat-form-field-prefix {
    font-size: 14px;
    bottom: -4px;
    margin: 0 $spacer-s;

    button {
      bottom: 4px;
    }
  }

  .mat-form-field-suffix {
    font-size: 14px;
    bottom: -4px;
    margin: 0 $spacer-s;

    button {
      bottom: 4px;
    }
  }

  /* In case of fill appearance, the spacing between the error and the next item is too small
  with this we add it in case it is invalid */
  .mat-form-field.mat-form-field-appearance-fill.mat-form-field-invalid {
    margin-bottom: $spacer-s;
    transition: margin-bottom 0.2s ease-out;
  }
}
