@use '@angular/material' as mat;
@use 'twaice-theme';

.loading {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
  }

  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #1562fc;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: antSpinMove 1s infinite linear alternate;
  }

  .ant-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0;
  }

  .ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.4s;
  }

  .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s;
  }

  .ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s;
  }

  .ant-spin-dot-spin {
    transform: rotate(0);
    animation: antRotate 0.8s infinite linear;
  }

  @keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }

  @keyframes antRotate {
    to {
      transform: rotate(360deg);
    }
  }
}
/* .loading screen is visible when app is not bootstrapped yet, my-app is empty */
twaice-fe-root:empty + .loading {
  opacity: 1;
  z-index: 100;
}
