// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@import './generated/colors.less';
@import './generated/fonts.less';
@import './generated/misc.less';

// global overrides
.anticon svg {
  // ant doesn't get along well with tailwind here so we unset the vertical-align
  vertical-align: unset;
}
