@use '@angular/material' as mat;
@mixin twaice-mat-button-toggle() {
  .mat-button-toggle-group {
    border: none;
    box-shadow: none;
    border-radius: $spacer-xs !important;
    padding: $spacer-xxs;
    gap: $spacer-xxs;
    background: mat.get-color-from-palette($twaice-primary, 200);
    color: $navy-blue;
  }

  .mat-button-toggle {
    border: none !important;
    border-radius: $spacer-xs !important;
    background: mat.get-color-from-palette($twaice-primary, 200) !important;

    .mat-button-toggle-label-content {
      line-height: $spacer-m !important;
      color: mat.get-contrast-color-from-palette($primary, 200);
    }
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($twaice-primary, 50) !important;

    .mat-button-toggle-label-content {
      font-weight: $font-weight-semibold !important;
    }
  }

  .mat-button-toggle-disabled {
    background: none !important;

    .mat-button-toggle-label-content {
      color: mat.get-color-from-palette($twaice-primary, 500) !important;
    }
  }
}
