@use '@angular/material' as mat;
@mixin twaice-mat-chip() {
  .mdc-evolution-chip__cell {
    height: initial !important;
  }
  .mat-chip {
    height: auto !important;
    line-height: 1;
    overflow-wrap: anywhere;
    background-color: mat.get-color-from-palette($accent, 200) !important;
    color: mat.get-contrast-color-from-palette($accent, 200) !important;
    font-weight: $font-weight-normal;

    &.disabled {
      background-color: mat.get-color-from-palette($primary, 200) !important;
      color: mat.get-contrast-color-from-palette($primary, 200) !important;
    }

    .mat-chip-ripple {
      display: none !important;
    }

    &:hover {
      background-color: mat.get-color-from-palette($accent, 300) !important;
      color: mat.get-contrast-color-from-palette($accent, 300) !important;
      opacity: 1;

      &.disabled {
        background-color: mat.get-color-from-palette($primary, 300) !important;
        color: mat.get-contrast-color-from-palette($primary, 300) !important;
      }
    }

    .mat-icon {
      color: mat.get-color-from-palette($accent, 600) !important;
      opacity: 1 !important;
    }

    &.disabled .mat-icon {
      color: mat.get-color-from-palette($primary, 600) !important;
    }
  }

  mat-chip-row.mat-mdc-chip {
    border-radius: 4px;
    border-style: none;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: auto;
    background: rgba(4, 15, 38, 0.04) !important;

    .mat-mdc-chip-ripple {
      display: none !important;
    }

    .mdc-evolution-chip__action--primary {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }

    .mdc-evolution-chip__action--trailing {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .mdc-evolution-chip--disabled {
      background: rgba(4, 15, 38, 0.04) !important;
      opacity: 0.24;
    }
  }
}
