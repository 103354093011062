@mixin twaice-mat-button() {
  .mat-flat-button {
    border-radius: $border-radius !important;
  }

  [mat-stroked-button][color='accent'] {
    border-color: currentColor;
    border-radius: $border-radius;
    border-width: 2px;

    text-transform: uppercase;
    font-size: $font-size-lg;
    padding: $spacer-s $spacer-m;

    mat-icon {
      margin-right: $spacer-xs;
    }

    &.normal-case {
      text-transform: none;
    }
  }
}
