@use '@angular/material' as mat;

@mixin twaice-mat-radio-button() {
  mat-radio-button {
    .mdc-radio__native-control {
      .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          border-color: $navy-blue !important;
        }
      }

      &:enabled:checked ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          border-color: $navy-blue !important;
        }
      }

      &:not(:checked) ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          border-color: $navy-blue !important;
        }
      }

      &:focus ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          border-color: $navy-blue !important;
        }
      }

      &:hover ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          border-color: $navy-blue !important;
        }
      }

      &:disabled ~ .mdc-radio__background {
        background-color: mat.get-color-from-palette($primary, 500) !important;
        border-color: mat.get-color-from-palette($primary, 500) !important;
      }
    }
  }
}
