@font-family:
  Proxima Nova,
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

@font-size-sm: 12px;
@font-size-base: 13px;
@font-size-lg: 16px;
@text-color: #1d273c;
