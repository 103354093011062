@use '@angular/material' as mat;
@mixin twaice-mat-select() {
  .mat-primary .mat-option {
    *,
    *::before,
    *::after {
      transition: none !important;
      animation: none !important;
    }

    &.mat-selected:not(.mat-option-disabled) {
      background-color: mat.get-color-from-palette($accent, 500) !important;
      color: mat.get-contrast-color-from-palette($accent, 500) !important;
    }

    &:focus {
      animation: none !important;
      border: solid 2px mat.get-color-from-palette($accent, 500) !important;
      background: none;
    }

    &:active {
      animation: none !important;
      border: solid 2px mat.get-color-from-palette($accent, 500) !important;
      // TODO - background still exists
      background: none !important;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mat-mdc-form-field-focus-overlay {
    background: none !important;
  }
}
