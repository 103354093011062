@use '@angular/material' as mat;

@mixin twaice-mat-chip-listbox() {
  mat-chip-listbox,
  mat-chip-list {
    mat-chip,
    mat-chip-option {
      background-color: rgba(4, 15, 38, 0.04) !important;
      border-radius: $spacer-xs !important;

      &.selected {
        background-color: rgba(4, 15, 38, 0.08) !important;
      }
    }
  }
}
