@use '@angular/material' as mat;
@mixin twaice-mat-tab() {
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: $navy-blue !important;
  }

  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
    color: $navy-blue !important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: $navy-blue !important;
  }
}
