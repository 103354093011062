@use '@angular/material' as mat;

@mixin twaice-mat-checkbox() {
  $background-border: 1.6px solid $navy-blue;

  mat-pseudo-checkbox.mat-pseudo-checkbox {
    width: $spacer;
    height: $spacer;
    border: $background-border !important;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
    background: $navy-blue !important;
  }

  mat-checkbox {
    .mdc-checkbox__native-control {
      .mdc-checkbox__background {
        border-color: $navy-blue !important;
      }

      &:enabled:checked ~ .mdc-checkbox__background {
        background-color: $navy-blue !important;
        border-color: $navy-blue !important;
      }

      &:not(:checked) ~ .mdc-checkbox__background {
        border-color: $navy-blue !important;
      }

      &:focus ~ .mdc-checkbox__background {
        border-color: $navy-blue !important;
      }

      &:hover ~ .mdc-checkbox__background {
        border-color: $navy-blue !important;
      }

      &:disabled ~ .mdc-checkbox__background {
        background-color: mat.get-color-from-palette($primary, 500) !important;
        border-color: mat.get-color-from-palette($primary, 500) !important;
      }

      &:indeterminate ~ .mdc-checkbox__background {
        background-color: $navy-blue !important;
      }
    }

    .mdc-checkbox__ripple {
      background-color: $navy-blue !important;
    }

    .mat-mdc-checkbox {
      .mdc-checkbox__background {
        width: $spacer;
        height: $spacer;
        border: $background-border !important;
      }
    }
  }
}
