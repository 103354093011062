@use '@angular/material' as mat;
@mixin twaice-mat-card() {
  mat-card.mat-card {
    margin: 0 $spacer-m $spacer-m 0;
    padding: 0 !important;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: none !important;
  }

  .mat-card:last-of-type {
    margin-right: 0;
  }

  .mat-card-header {
    background-color: mat.get-color-from-palette($primary, 400);
    padding: 0 $spacer-md;
    border-radius: $border-radius $border-radius 0 0;
    align-items: center;
    height: 90px;

    color: mat.get-contrast-color-from-palette($primary, 400);
    text-transform: uppercase;
    font-weight: $font-weight-light;
    font-size: $font-size-2xl;

    .mat-card-header-text {
      /* CSS styles go here */
      margin: 0; // for example to remove the margin
    }

    .mat-card-title {
      margin-bottom: 8px;
      font-weight: $font-weight-light;
      font-size: $font-size-2xl;

      &[disabled='true'],
      [aria-disabled='true'] {
        color: mat.get-color-from-palette($primary, 500);
        cursor: default;
        user-select: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .mat-card-subtitle {
      color: mat.get-color-from-palette($primary, 800);
      margin-bottom: $spacer-s;
      text-transform: initial;

      &:last-child {
        margin-bottom: 0;
      }

      &.mat-card-subtitle--xs {
        font-size: $font-size-xs;
      }
    }

    .buttons,
    & > button {
      position: absolute;
      right: $spacer-s;
      top: $spacer;
    }
  }

  .mat-card-content {
    padding: $spacer-md;
  }

  .mat-card--wide {
    .mat-card-content {
      padding: $spacer-l;
    }

    .mat-card-header {
      padding: $spacer-md $spacer-l $spacer-m;
    }
  }
}
