@use '@angular/material' as mat;
@mixin twaice-mat-table() {
  .mat-table {
    box-shadow: none;
    border: solid 1px mat.get-color-from-palette($primary, 200);
    width: 100%;
    box-sizing: border-box;

    .mat-sort-header-arrow {
      color: mat.get-color-from-palette($primary, 600);
    }

    mat-row,
    mat-header-row,
    mat-footer-row,
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      border-bottom-color: mat.get-color-from-palette($primary, 200);

      &:not(:first-child) {
        margin-left: 40px;
      }
    }

    td.mat-cell {
      color: mat.get-color-from-palette($primary, 900);
    }

    .mat-header-cell {
      color: mat.get-color-from-palette($primary, 600);
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      z-index: 0 !important;

      .mat-icon {
        color: mat.get-color-from-palette($primary, 600) !important; // overriding the default style on the
        margin-left: 0.1em;
      }
    }

    tr.mat-row,
    tr.mat-footer-row {
      height: 35px;
      font-size: $font-size-base;

      &.clickable:hover {
        cursor: pointer;
      }
    }

    /* class hoverAccent on the mat-table element enables the hover style behaviour */
    &.hoverAccent {
      tr.mat-row:hover {
        background-color: mat.get-color-from-palette($primary, 100);
        position: relative;

        &:after {
          content: '';
          position: absolute;
          background-color: mat.get-color-from-palette($accent, 500);
          left: 0;
          width: 3px;
          height: 100%;
          z-index: 10;
        }
      }
    }

    tr.mat-header-row {
      background-color: mat.get-color-from-palette($primary, 100);
    }
  }

  .mat-paginator {
    .mat-paginator-range-actions {
      flex-grow: 1;

      .mat-paginator-range-label {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  .mat-card-content > .mat-table {
    // if we have table within mat-card we dont want any border
    border: 0;
  }
}
