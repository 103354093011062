@use '@angular/material' as mat;

@mixin twaice-mat-dialog() {
  mat-dialog-container {
    font-family: $font-family-proxima-nova !important;

    .mat-mdc-dialog-surface {
      border-radius: $spacer-s !important;
    }
  }
}
